export enum CommodityFormFields {
  NmfcSetClass = 'nmfcSetClass',
  ChargeToCode = 'chargeToCode',
  Pieces = 'pieces',
  Weight = 'weight',
  Packaging = 'packaging',
  Nmfc = 'nmfc',
  Class = 'class',
  Description = 'description',
  OriginalDescription = 'originalDescription',
  HazmatInd = 'hazmatInd',
  FilteredPackagingListOptions = 'filteredPackagingListOptions',
  FilteredCommodityClassListOptions = 'filteredCommodityClassListOptions',
  NmfcListOptions = 'nmfcListOptions',
  SequenceNbr = 'sequenceNbr',
  ActionCd = 'actionCd',
  HazmatDetails = 'hazmatDetails'
}
