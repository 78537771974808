import { Injectable } from '@angular/core';
import { BaseService } from '../base-service';
import { DataApiService } from '../data-api/data-api.service';
import { FormatValidationService } from '../format-validation/format-validation.service';
import { BillOfLading } from './model/bill-of-lading';

import { Envelope } from '@xpo-ltl/api-common';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../../core/model/config-manager-properties.enum';

@Injectable()
export class BolApiService extends BaseService {

  constructor(
      private dataApiService: DataApiService,
      private configManager: ConfigManagerService,
      private formatValidator: FormatValidationService) {
    super();
  }

  /**
   * https://tctsr-api.ltl.xpo.com/billoflading/1.0/billsoflading
   */
  getBillOfLading(value: string): Promise<BillOfLading> {

    if ( value === null ) {
      throw new Error('Bol Instance Id or ProNumber is Required');
    }

    const id = value.trim();
    let bolUrl = this.getEndPoint();

    if (this.formatValidator.isValidProNumber(id)) {
      bolUrl += `?proNbr=${id}`;
    } else {
      bolUrl += `?bolInstId=${id}`;
    }

    return new Promise((resolve, reject) => {
      this.dataApiService.get(bolUrl, null, this._dataOptions).then(
          (response: Envelope) => {
            resolve(response['data'] as BillOfLading);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  protected getEndPoint(): string {
    return this.configManager.getSetting(ConfigManagerProperties.bolApiEndpoint);
  }
}
